/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from '@env/environment';
import { Idle } from '@ng-idle/core';
import { SupersetService } from './superset-service/superset.service';
import { takeUntil } from 'rxjs/operators';
import { XGSCustomer } from '@common/models/user-management.model';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {
  Dashboard,
  FilterState,
  FilterType,
  NativeFilterConfig,
  NativeFilters,
  QueryContextFilter,
  SupersetQueryFilters,
  SupersetQueryParams,
  Target
} from '@common/models/superset.model';
import { LoadingService } from '@gravity-angular/layout';
import { CustomerService } from 'app/shared/services/customer-service/customer.service';
import { DatadogService } from 'app/shared/services/datadog-services/datadog.service';

/**
 * Component to display Superset dashboard in iframe
 */
@Component({
  selector: 'app-superset',
  templateUrl: './superset.component.html',
  styleUrls: ['./superset.component.scss']
})
export class SupersetComponent implements OnInit, AfterViewInit, OnDestroy {
  url: string;
  id: string;
  xgsCustomer: XGSCustomer;
  paramEvent: boolean;

  onDestroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly idle: Idle,
    private readonly supersetService: SupersetService,
    private readonly customerService: CustomerService,
    private readonly datadogService: DatadogService,
    private readonly location: Location,
    private readonly loadingService: LoadingService,
    private readonly route: ActivatedRoute
  ) {
    this.customerService.currentCustomer
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(utilityValue => {
        this.xgsCustomer = utilityValue.customer;
      });
  }

  /**
   * Initializes component by setting URL path based on query params if present
   */
  ngOnInit(): void {
    this.loadingService.showLoading(true);
    this.route.queryParams.subscribe(async (params: SupersetQueryParams) => {
      if (params.customer) {
        this.paramEvent = true;
        await this.setCustomer(params.customer);
      }

      this.url =
        Object.keys(params).length > 0 && params.report
          ? await this.setUrlPath(params)
          : environment.supersetUrl;
      this.id = Math.random().toString(36).substring(2);
      this.paramEvent = false;

      this.loadingService.showLoading(false);
      this.location.go('/superset');
    });
  }

  /**
   * Stops subscription to customer service
   */
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  /**
   * After view initialization, listens for messages from Superset child window
   */
  ngAfterViewInit(): void {
    // Check for messages from superset child window sent via postMessage.
    // If an activityDetected event is detected, interrupt the idle timer.
    // If a pathChange event is detected, update the path in local storage.
    window.addEventListener('message', e => {
      if (
        e.origin !== `https://${environment.domain}` ||
        e.origin !== `https://www.${environment.domain}`
      ) {
        return;
      }

      if (e.data.eventName === 'activityDetected') {
        this.idle.interrupt();
      } else if (e.data.eventName === 'pathChange') {
        localStorage.setItem('supersetPath', e.data.currentPath);
      }
    });

    // If a customerChanged event is detected, reload the iframe by generating a new ID
    window.addEventListener('customerChanged', async () => {
      if (!this.paramEvent) {
        this.loadingService.showLoading(true);
        this.url = await this.updateUrlPath();
        this.id = Math.random().toString(36).substring(2);
        this.loadingService.showLoading(false);
      }
    });
  }

  /**
   * Updates URL path based on customer and/or dashboard title etc.
   * @returns URL to be used in iframe
   */
  async updateUrlPath(): Promise<string> {
    const currentPath = localStorage.getItem('supersetPath');
    let newPath = currentPath;

    // if the path matches the pattern "/superset/dashboard/<title>", extract the title and find the dashboard with the same
    // title in the new customer's dashboards
    if (currentPath?.match(/^\/superset\/dashboard\/.*$/)) {
      // The title is the 3rd path segment before the last underscore with underscores replaced with spaces
      // e.g. "Device_Event_History_us-east-1-0001" -> "Device Event History"
      const slug = currentPath?.split('/')[3];
      const partialSlug = slug?.split('_')?.slice(0, -1)?.join('_');

      const customerDashboards = await this.supersetService.getDashboards(
        this.xgsCustomer.customerId.toLowerCase()
      );
      const newDashboard = customerDashboards.find(d => {
        return d.url.includes(partialSlug);
      });

      // If a dashboard with the same title exists in the new customer's dashboards, use its URL
      // Otherwise, fall back to the dashboard list
      newPath = newDashboard ? newDashboard.url : 'dashboard/list';
    }

    return newPath
      ? `${environment.supersetUrl}?path=${encodeURI(newPath)}`
      : environment.supersetUrl;
  }

  /**
   * Builds Superset URL path to be used based on query params provided in URL
   * @param queryParams query params passed in URL to Superset
   * @returns URL to be used in iframe
   */
  async setUrlPath(queryParams: SupersetQueryParams): Promise<string> {
    const customerDashboards = await this.supersetService.getDashboards(
      this.xgsCustomer.customerId.toLowerCase()
    );

    const newDashboard = customerDashboards.find(d => {
      return (
        d.dashboard_title.toUpperCase() === queryParams.report.toUpperCase()
      );
    });

    let newPath = newDashboard ? newDashboard.url : 'dashboard/list';

    if (newDashboard && queryParams.filters) {
      const nativeFilterKey = await this.nativeFilters(
        newDashboard,
        queryParams
      );

      newPath = nativeFilterKey
        ? `${encodeURI(newDashboard.url)}?native_filters_key=${nativeFilterKey}`
        : newPath;
    }

    return `${environment.supersetUrl}?path=${encodeURI(newPath)}`;
  }

  /**
   * Checks that customer Id passed is valid
   * then begins process of setting customer
   * @param customerID UDL customer ID
   */
  async setCustomer(customerID: string): Promise<void> {
    const selectCustomer = this.customerService.validateCustomer(customerID);

    if (selectCustomer) {
      this.customerService.onUtilityChanged(selectCustomer);
    }
  }

  /**
   * Gets dashboard params to match query param filter values,
   * creates native filter and stored in Superset (filter_state)
   * @param newDashboard Matching Dashboard from query params
   * @param queryParams values passed in URL to be applied
   * @returns key value representing native_filters_key
   */
  async nativeFilters(
    newDashboard: Dashboard,
    queryParams: SupersetQueryParams
  ): Promise<string> {
    try {
      const dashboardParams = await this.supersetService.getDashboardFilters(
        newDashboard.id
      );

      const queryFilters = JSON.parse(
        queryParams.filters
      ) as SupersetQueryFilters[];

      const customerResponse = await this.customerService.getCustomerConfig([
        queryParams.customer.toLowerCase()
      ]);
      const customerConfig = customerResponse.data[0];

      // Automatically set Schema value based on customer passed
      queryFilters.push({
        name: 'Schema',
        value: customerConfig.db_schema_name
      });

      const nativeFilters = await this.createNativeFilter(
        dashboardParams.nativeFilterConfig,
        queryFilters
      );

      // Native filter stored as filter_state to avoid WAF blocks due to URL length
      const nativeFilterKey =
        await this.supersetService.createDashboardFilterState(
          newDashboard.id,
          nativeFilters
        );

      return nativeFilterKey.key;
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Failed to create native Superset filter'
      });
    }
  }

  /**
   * Constructs native filter to be used by Superset
   * @param nativeFilterConfig list of native filter configs
   * @param queryFilters Filter names/values provided in query params
   * @returns native filter constructed to pass to Superset
   */
  async createNativeFilter(
    nativeFilterConfig: NativeFilterConfig[],
    queryFilters: SupersetQueryFilters[]
  ): Promise<NativeFilters> {
    const nativeFilters = {} as NativeFilters;
    const hiddenFilters = [];

    for (const filter of nativeFilterConfig) {
      nativeFilters[filter.id] = {
        extraFormData: filter.defaultDataMask.extraFormData,
        filterState: filter.defaultDataMask.filterState,
        id: filter.id,
        ownState: {}
      };

      if (filter.defaultDataMask.__cache) {
        nativeFilters[filter.id].__cache = filter.defaultDataMask.__cache;
      }

      const filterMatch = queryFilters.find(queryFilter => {
        return queryFilter.name === filter.name;
      });

      if (filterMatch) {
        this.applyFilterMatch(filter, nativeFilters, filterMatch);
      } else if (filter.controlValues?.hideFilter) {
        nativeFilters[filter.id].__cache = nativeFilters[filter.id].__cache
          ? nativeFilters[filter.id].__cache
          : ({} as FilterState);
        hiddenFilters.push(filter);
      }
    }

    if (hiddenFilters.length > 0) {
      await this.applyHiddenFilters(hiddenFilters, nativeFilters);
    }

    return nativeFilters;
  }

  /**
   * Updates native_filter's filters
   * to use filters from query params
   * @param filter default native_filter for dashboard
   * @param nativeFilters native_filters to be applied
   * @param filterMatch match filter from query params
   */
  applyFilterMatch(
    filter: NativeFilterConfig,
    nativeFilters: NativeFilters,
    filterMatch: SupersetQueryFilters
  ): void {
    if (filter.filterType === FilterType.TIME) {
      nativeFilters[filter.id].extraFormData.time_range = nativeFilters[
        filter.id
      ].filterState.value = filterMatch.value;
    } else {
      this.applyQueryFilters(nativeFilters, filter, filterMatch);
    }
  }

  /**
   * Loops through and applies filters
   * to native_filter for each hidden filter
   * @param hiddenFilters list of hidden filters from dashboard
   * @param nativeFilters native_filters to be applied
   */
  async applyHiddenFilters(
    hiddenFilters: NativeFilterConfig[],
    nativeFilters: NativeFilters
  ): Promise<void> {
    for (const hiddenFilter of hiddenFilters) {
      for (const target of hiddenFilter.targets) {
        const filterParams = this.getHiddenFilterParams(
          nativeFilters,
          hiddenFilter
        );

        await this.setHiddenFilterValues(
          nativeFilters,
          hiddenFilter,
          target,
          filterParams
        );
      }
    }
  }

  /**
   * Creates filter params to be used for
   * superset /get-dashboard-param-values API call
   * @param nativeFilters native_filters to be applied
   * @param hiddenFilter hidden filter from dashboard
   * @returns list of query contexts for API call
   */
  getHiddenFilterParams(
    nativeFilters: NativeFilters,
    hiddenFilter: NativeFilterConfig
  ): QueryContextFilter[] {
    return [
      ...(nativeFilters[hiddenFilter.id].extraFormData?.filters
        ? nativeFilters[hiddenFilter.id].extraFormData.filters
        : []),
      ...[].concat(
        ...hiddenFilter.cascadeParentIds.map(cascadeParentId => {
          return nativeFilters[cascadeParentId].extraFormData.filters;
        })
      )
    ];
  }

  /**
   * Retrieves values for hidden filter to be applied to native_filter
   * @param nativeFilters native_filters to be applied
   * @param hiddenFilter hidden filter from dashboard
   * @param target hidden filter target
   * @param filterParams list of query contexts for API call
   */
  async setHiddenFilterValues(
    nativeFilters: NativeFilters,
    hiddenFilter: NativeFilterConfig,
    target: Target,
    filterParams: QueryContextFilter[]
  ): Promise<void> {
    const filterValues = await this.supersetService.getDashboardFilterValues(
      target.datasetId,
      target.column.name,
      filterParams
    );

    nativeFilters[hiddenFilter.id].filterState.label = nativeFilters[
      hiddenFilter.id
    ].__cache.label = filterValues[0];
    nativeFilters[hiddenFilter.id].filterState.value = nativeFilters[
      hiddenFilter.id
    ].__cache.value = filterValues;
    nativeFilters[hiddenFilter.id].extraFormData.filters = [
      {
        col: target.column.name,
        op: 'IN',
        val: filterValues
      }
    ];
  }

  /**
   * Updates nativeFilter to contain filter values provided by query params
   * @param nativeFilter Native filter object to be passed to Superset
   * @param filter native filter config from dashboard metadata
   * @param filterMatch Matching filter from query params
   */
  applyQueryFilters(
    nativeFilter: NativeFilters,
    filter: NativeFilterConfig,
    filterMatch: SupersetQueryFilters
  ) {
    nativeFilter[filter.id].extraFormData.filters = filter.targets.map(
      target => {
        return {
          col: target.column.name,
          op: 'IN',
          val: [filterMatch.value]
        };
      }
    );

    if (!nativeFilter[filter.id].__cache) {
      nativeFilter[filter.id].__cache = {
        validateStatus: false,
        validateMessage: false
      } as FilterState;
    }

    nativeFilter[filter.id].filterState.label = nativeFilter[
      filter.id
    ].__cache.label = filterMatch.value;
    nativeFilter[filter.id].filterState.value = nativeFilter[
      filter.id
    ].__cache.value = [filterMatch.value];
  }
}
