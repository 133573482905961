import { Injectable } from '@angular/core';
import {
  mapToCanActivate,
  mapToCanDeactivate,
  mapToCanMatch,
  Route,
  Router
} from '@angular/router';
import { SideNavModel, SideNavRoute } from '@gravity-angular/layout';
import {
  CanDeactivateGuard,
  XgsGuardGuard
} from '../../../auth/auth-guards/xgs-guard.guard';
import { CustomTranslatorService } from '../custom-translator/custom-translator.service';
import { XgsUmService } from 'app/auth/xgs-service/xgs-um.service';
import { UserFunctionsService } from 'app/user-management/user/user-functions-service/user-functions.service';
import {
  ExdlPermissions,
  UDLUserData,
  UtilityOptionValue
} from '@common/models/user-management.model';
import {
  FeatureFlag,
  FeatureFlagsService
} from '../feature-flags/feature-flags.service';
import { CustomerService } from '../customer-service/customer.service';
import {
  AuthGuard,
  DeactivateRestrictedRoute,
  SyncGuardHelper
} from 'app/auth/auth-guards/auth.guard';
import { DatadogService } from '../datadog-services/datadog.service';
import { CustomerOnboardComponent } from 'app/customer-onboard/customer-onboard.component';
import { UsageComponent } from 'app/usage/usage.component';

/**
 * Service to dynamically update routes and side navigation based on user/customer access.
 */
@Injectable({
  providedIn: 'root'
})
export class DynamicRoutingService {
  xdlRoutes: XDLRoute[] = [];
  selectedCustomer: UtilityOptionValue;

  constructor(
    private readonly router: Router,
    private readonly customTranslatorService: CustomTranslatorService,
    private readonly xgsUmService: XgsUmService,
    private readonly datadogService: DatadogService,
    private readonly featureFlagsService: FeatureFlagsService,
    private readonly userFunctionsService: UserFunctionsService,
    private readonly customerService: CustomerService
  ) {
    this.customerService.currentCustomer.subscribe(utilityValue => {
      this.selectedCustomer = utilityValue;
    });
  }

  /**
   * Initializes the XDL routes list with the default routes.
   */
  initXdlRoutes(): void {
    this.xdlRoutes = [
      {
        feature: 'superset',
        customerRestricted: false,
        sideNav: {
          route: 'superset',
          display: this.customTranslatorService.translate(
            $localize`:@@app_sideNav_superset:Apache Superset`,
            'app_sideNav_superset'
          ),
          icon: 'all_inclusive',
          id: 'supersetDashboardLink'
        }
      },
      {
        feature: 'user-profile',
        customerRestricted: false,
        sideNav: {
          route: 'user/user-detail',
          display: this.customTranslatorService.translate(
            $localize`:@@app_sideNav_userProfile:User Profile`,
            'app_sideNav_userProfile'
          ),
          icon: 'account_box',
          id: 'userDetailLink'
        }
      },
      {
        customerRestricted: false,
        feature: 'user-list',
        permission: ExdlPermissions.ACCESS_USERS,
        performOperation: true,
        sideNav: {
          route: 'user/user-list',
          display: this.customTranslatorService.translate(
            $localize`:@@app_sideNav_userList:Users List`,
            'app_sideNav_userList'
          ),
          icon: 'supervisor_account',
          id: 'userListLink'
        }
      },
      {
        customerRestricted: false,
        feature: 'role-list',
        permission: ExdlPermissions.ACCESS_USERS,
        sideNav: {
          route: 'role/role-list',
          display: this.customTranslatorService.translate(
            $localize`:@@app_sideNav_roleList:Roles List`,
            'app_sideNav_roleList'
          ),
          icon: 'verified_user',
          id: 'roleListLink'
        }
      },
      {
        feature: 'scheduler',
        customerRestricted: false,
        permission: ExdlPermissions.SCHEDULER,
        sideNav: {
          route: 'scheduler',
          display: this.customTranslatorService.translate(
            $localize`:@@app_sideNav_udlScheduler:Scheduler`,
            'app_sideNav_udlScheduler'
          ),
          icon: 'schedule',
          id: 'schedulerLink'
        },
        route: {
          path: 'scheduler',
          canMatch: mapToCanMatch([XgsGuardGuard]),
          canDeactivate: mapToCanDeactivate([
            CanDeactivateGuard,
            DeactivateRestrictedRoute
          ]),
          /**
           * Lazy loads the Scheduler module.
           * @returns - The Scheduler module.
           */
          loadChildren: async () => {
            const m = await import('../../../scheduler/scheduler.module');

            return m.SchedulerModule;
          }
        }
      },
      {
        feature: 'resource-usage',
        customerRestricted: false,
        permission: ExdlPermissions.RESOURCE_USAGE,
        sideNav: {
          route: 'usage-screen',
          display: this.customTranslatorService.translate(
            $localize`:@@app_sideNav_usageScreen:Usage Screen`,
            'app_sideNav_usageScreen'
          ),
          icon: 'analytics',
          id: 'usageScreenLink'
        },
        route: {
          path: 'usage-screen',
          component: UsageComponent,
          pathMatch: 'full',
          canActivate: mapToCanActivate([SyncGuardHelper]),
          canDeactivate: mapToCanDeactivate([DeactivateRestrictedRoute]),
          data: {
            syncGuards: [XgsGuardGuard, AuthGuard],
            permission: ExdlPermissions.RESOURCE_USAGE
          }
        }
      },
      {
        customerRestricted: true,
        feature: FeatureFlag.DATA_IMPORT,
        featureFlag: FeatureFlag.DATA_IMPORT,
        permission: ExdlPermissions.DATA_IMPORT,
        sideNav: {
          route: 'data-import',
          display: 'Data Import',
          icon: 'cloud_upload',
          id: 'dataImportLink'
        },
        route: {
          path: 'data-import',
          canMatch: mapToCanMatch([XgsGuardGuard]),
          canDeactivate: mapToCanDeactivate([DeactivateRestrictedRoute]),
          data: {
            access: FeatureFlag.DATA_IMPORT
          },
          /**
           * Lazy loads the Data Import module.
           * @returns - The Data Import module.
           */
          loadChildren: async () => {
            const m = await import('../../../data-import/data-import.module');

            return m.DataImportModule;
          }
        }
      },
      {
        customerRestricted: false,
        feature: FeatureFlag.HEALTH_MONITOR,
        featureFlag: FeatureFlag.HEALTH_MONITOR,
        permission: ExdlPermissions.HEALTH_MONITOR,
        sideNav: {
          route: 'health-monitor',
          display: 'Health Monitor',
          icon: 'favorite',
          id: 'healthMonitorLink'
        },
        route: {
          path: 'health-monitor',
          canMatch: mapToCanMatch([XgsGuardGuard]),
          canDeactivate: mapToCanDeactivate([DeactivateRestrictedRoute]),
          data: {},
          /**
           * Lazy loads the Health Monitor module.
           * @returns - The Health Monitor module.
           */
          loadChildren: async () => {
            const m = await import(
              '../../../health-monitor/health-monitor.module'
            );

            return m.HealthMonitorModule;
          }
        }
      },
      {
        customerRestricted: false,
        feature: 'cluster-analysis',
        featureFlag: FeatureFlag.CLUSTER_ANALYSIS,
        permission: ExdlPermissions.CLUSTER_ANALYSIS,
        sideNav: {
          route: 'ClusterAnalysis/cluster_analysis_type_selection',
          display: this.customTranslatorService.translate(
            $localize`:@@app_sideNav_clusterAnalysis:Cluster Analysis`,
            'app_sideNav_clusterAnalysis'
          ),
          icon: 'bar_chart',
          id: 'clusterAnalysisLink'
        },
        route: {
          path: 'ClusterAnalysis',
          canMatch: mapToCanMatch([XgsGuardGuard]),
          canDeactivate: mapToCanDeactivate([DeactivateRestrictedRoute]),
          /**
           * Lazy loads the Cluster Analysis module.
           * @returns - The Cluster Analysis module.
           */
          loadChildren: async () => {
            const m = await import(
              '../../../cluster-analysis/cluster-analysis.module'
            );

            return m.ClusterAnalysisModule;
          }
        }
      },
      {
        customerRestricted: false,
        feature: 'app-store',
        featureFlag: FeatureFlag.APP_STORE,
        permission: ExdlPermissions.APP_STORE,
        sideNav: {
          route: 'appstore',
          display: this.customTranslatorService.translate(
            $localize`:@@app_sideNav_appStore:AppStore`,
            'app_sideNav_appStore'
          ),
          icon: 'shop',
          id: 'appStore'
        },
        route: {
          path: 'appstore',
          canMatch: mapToCanMatch([XgsGuardGuard]),
          canDeactivate: mapToCanDeactivate([DeactivateRestrictedRoute]),
          /**
           * Lazy loads the App Store module.
           * @returns - The App Store module.
           */
          loadChildren: async () => {
            const m = await import(
              '../../../appstore-stratusIQ+/appstore.module'
            );

            return m.AppStoreModule;
          }
        }
      },
      {
        customerRestricted: false,
        feature: 'business-rule',
        permission: ExdlPermissions.BUSINESS_RULE_ENGINE,
        featureFlag: FeatureFlag.BUSINESS_RULE_ENGINE,
        sideNav: {
          route: 'bre/business-rule',
          display: this.customTranslatorService.translate(
            $localize`:@@app_sideNav_businessRules:Business Rules`,
            'app_sideNav_businessRules'
          ),
          icon: 'assignment',
          id: 'testpage'
        },
        route: {
          path: 'bre',
          canMatch: mapToCanMatch([XgsGuardGuard]),
          canDeactivate: mapToCanDeactivate([DeactivateRestrictedRoute]),
          /**
           * Lazy loads the Business Rule Engine module.
           * @returns - The Business Rule Engine module.
           */
          loadChildren: async () => {
            const m = await import(
              '../../../bre-work-queue/bre-work-queue.module'
            );

            return m.BreWorkQueueModule;
          }
        }
      },
      {
        customerRestricted: false,
        feature: 'work-queue',
        permission: ExdlPermissions.BUSINESS_RULE_ENGINE,
        featureFlag: FeatureFlag.BUSINESS_RULE_ENGINE,
        sideNav: {
          route: 'bre/work-queue',
          display: this.customTranslatorService.translate(
            $localize`:@@app_sideNav_workQueue:Work Queue`,
            'app_sideNav_workQueue'
          ),
          icon: 'engineering',
          id: 'workqueue'
        }
      },
      {
        customerRestricted: false,
        feature: 'customer-onboard',
        permission: ExdlPermissions.CUSTOMER_ONBOARDING,
        sideNav: {
          route: 'customer-onboard',
          display: 'Customer Onboarding',
          icon: 'dashboard',
          id: 'customerOnboardingLink'
        },
        route: {
          path: 'customer-onboard',
          component: CustomerOnboardComponent,
          pathMatch: 'full',
          canActivate: mapToCanActivate([SyncGuardHelper]),
          data: {
            syncGuards: [XgsGuardGuard, AuthGuard],
            permission: ExdlPermissions.CUSTOMER_ONBOARDING
          }
        }
      }
    ];
  }

  /**
   * Sets the routes and updates the side navigation based on user access.
   * @param sideNavContent - The side navigation content to update.
   * @param userData - The user data containing access information.
   */
  setRoutes(sideNavContent: SideNavModel, userData: UDLUserData): void {
    if (this.xdlRoutes.length === 0) {
      this.initXdlRoutes();
    }

    for (const xdlRoute of this.xdlRoutes) {
      try {
        if (this.hasAccess(xdlRoute, userData)) {
          this.updateRoutes(xdlRoute);
          this.updateSideNav(xdlRoute, sideNavContent);
        } else {
          this.removeRoutes(xdlRoute);
          this.removeSideNav(xdlRoute, sideNavContent);
        }
      } catch (error) {
        this.datadogService.errorTracking(error, {
          message: `Error processing route: ${xdlRoute.feature}`
        });
      }
    }
  }

  /**
   * Checks if the user has access to a specific route based on feature flags, permissions, and customer access.
   * @param xdlRoute - The route to check access for.
   * @param userData - The user data containing access information.
   * @returns - True if the user has access to the route, false otherwise.
   */
  hasAccess(xdlRoute: XDLRoute, userData: UDLUserData): boolean {
    const featureFlagAccess = this.checkFeatureFlagAccess(xdlRoute);
    const permissionAccess = this.checkPermissionAccess(xdlRoute, userData);
    const customerAccess = this.checkCustomerAccess(xdlRoute);

    return featureFlagAccess && permissionAccess && customerAccess;
  }

  /**
   * Checks if the user has access to a specific route based on feature flags, permissions, and customer access.
   * @param xdlRoute - The route to check access for.
   * @param userData - The user data containing access information.
   * @returns - True if the user has access to the route, false otherwise.
   */
  checkFeatureFlagAccess(xdlRoute: XDLRoute): boolean {
    return xdlRoute.featureFlag
      ? this.featureFlagsService.isFeatureEnabled(xdlRoute.featureFlag)
      : true;
  }

  /**
   * Checks if the user has the required permission for a specific route.
   * @param xdlRoute - The route to check the permission for.
   * @param userData - The user data containing permissions information.
   * @returns - True if the user has the required permission or if no permission is specified, false otherwise.
   */
  checkPermissionAccess(xdlRoute: XDLRoute, userData: UDLUserData): boolean {
    if (!xdlRoute.permission) {
      return true;
    }

    if (
      !this.userFunctionsService.permissionCheck(
        userData.xgsPermissions,
        xdlRoute.permission
      )
    ) {
      return false;
    }

    const customers = this.userFunctionsService.customersWithPermission(
      userData,
      xdlRoute.permission
    );

    return (
      customers.includes('AllCustomers') ||
      customers.includes(this.selectedCustomer?.customer?.customerId)
    );
  }

  /**
   * Checks if the route is restricted to certain customers and if the current customer has access.
   * @param xdlRoute - The route to check customer access for.
   * @returns - True if the route is not customer restricted or if the current customer has access, false otherwise.
   */
  checkCustomerAccess(xdlRoute: XDLRoute): boolean {
    return xdlRoute.customerRestricted
      ? this.customerAccess(xdlRoute.feature)
      : true;
  }

  /**
   * Checks if the current customer has access to a specific feature.
   * @param feature - The feature to check access for.
   * @returns - True if the current customer has access to the feature, false otherwise.
   */
  customerAccess(feature: string): boolean {
    return this.selectedCustomer?.access?.includes(feature);
  }

  /**
   * Updates the router configuration with a new route if it does not already exist.
   * @param xdlRoute - The route configuration to be added.
   */
  updateRoutes(xdlRoute: XDLRoute): void {
    const currentRoutes = this.router.config;
    const newRoute = xdlRoute.route;

    if (!newRoute) {
      return;
    }

    const routeExists = currentRoutes.some(route => {
      return route.path === newRoute.path;
    });

    if (!routeExists) {
      this.router.resetConfig([newRoute, ...currentRoutes]);
    }
  }

  /**
   * Removes a specified route from the router configuration.
   * @param xdlRoute - The route configuration to be removed.
   */
  removeRoutes(xdlRoute: XDLRoute): void {
    if (!xdlRoute || !xdlRoute.route || !xdlRoute.route.path) {
      return;
    }

    const currentRoutes = this.router.config;
    const updatedRoutes = currentRoutes.filter(route => {
      return route.path !== xdlRoute.route.path;
    });

    if (currentRoutes.length !== updatedRoutes.length) {
      try {
        this.router.resetConfig(updatedRoutes);
      } catch (error) {
        this.datadogService.errorTracking(error, {
          message: `Failed to reset router configuration for route: ${xdlRoute.feature}`
        });
      }
    }
  }

  /**
   * Updates the side navigation content with a new route if it does not already exist.
   * @param xdlRoute - The route configuration to be added to the side navigation.
   * @param sideNavContent - The side navigation content to be updated.
   */
  updateSideNav(xdlRoute: XDLRoute, sideNavContent: SideNavModel): void {
    const sideNavRoute = sideNavContent.routes.find(route => {
      return route.route === xdlRoute.sideNav.route;
    });

    if (!sideNavRoute) {
      sideNavContent.routes.push(xdlRoute.sideNav);
      if (
        xdlRoute.permission === ExdlPermissions.ACCESS_USERS &&
        xdlRoute.performOperation
      ) {
        this.xgsUmService.generateUsers();
        xdlRoute.performOperation = false;
      }
    }
  }

  /**
   * Removes a specified route from the side navigation content.
   * @param xdlRoute - The route configuration to be removed from the side navigation.
   * @param sideNavContent - The side navigation content to be updated.
   */
  removeSideNav(xdlRoute: XDLRoute, sideNavContent: SideNavModel): void {
    const index = sideNavContent.routes.findIndex(screen => {
      return screen.route === xdlRoute.sideNav.route;
    });

    if (index !== -1) {
      sideNavContent.routes.splice(index, 1);
    }
  }
}

export interface XDLRoute {
  route?: Route; // Angular Route
  sideNav: SideNavRoute; // SideNavRoute
  feature: string; // Feature name
  customerRestricted: boolean; // Access limited to customers
  permission?: string; // XGS Permission required for access
  featureFlag?: FeatureFlag; // Feature flag value used for customer access
  performOperation?: boolean; // Perform operation on route access
}
